import { useContext, useState, useRef } from "react";
import { Redirect, Link } from "react-router-dom";
import AuthContext from "contexts/AuthContext";
import { auth } from "firebase";
import { signInWithEmailAndPassword } from "@firebase/auth";

export default function Login() {
  const [isAuthenticated, setAuthentication] = useContext(AuthContext);
  const [isButtonDisabled, setButtonState] = useState(false);
  const emailRef = useRef("");
  const passwordRef = useRef("");
  async function login(event) {
    event.preventDefault();
    setButtonState(true);
    const email = emailRef.current.value.trim();
    const password = passwordRef.current.value;
    try {
      await signInWithEmailAndPassword(auth, email, password);
      setAuthentication(true);
      console.log("success");
    } catch (error) {
      let message = "";
      switch (error.code) {
        case "auth/user-not-found":
          message = "No account";
          break;
        case "auth/wrong-password":
          message = "Wrong password. Try again";
          break;
        default:
          message = error.message;
      }
      passwordRef.current.value = "";
      setButtonState(false);
      console.log(message);
    }
  }
  if (isAuthenticated === null) return null;
  if (isAuthenticated === true) return <Redirect to="/dashboard" />;
  return (
    <div>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Log in to your account
          </h2>
        </div>
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={login}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700 text-left"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    ref={emailRef}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700 text-left"
                >
                  Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    ref={passwordRef}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  disabled={isButtonDisabled}
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Log in
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
