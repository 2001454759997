import { useContext } from "react"
import { Link, useHistory } from "react-router-dom"
import AuthContext from "contexts/AuthContext"
import { auth } from "firebase"
import { ReactComponent as Logo } from 'assets/logo.svg';

export default function Header() {
    const [isAuthenticated, setAuthentication] = useContext(AuthContext)

    async function signOut() {
        await auth.signOut();
        setAuthentication(false);
    }

    function renderButtons() {
        if (isAuthenticated === false && window.location.pathname !== '/login' && window.location.pathname !== '/') {
            return (
                <Link to="/login">Login</Link>
            )
        } else if (isAuthenticated === true) {
            return (
                <div className="flex justify-end">
                    <button className="text-white hover:bg-indigo-500 hover:bg-opacity-75 px-3 py-2 rounded-md text-sm font-medium" onClick={history.goBack}>Back</button>
                    <button className="text-white hover:bg-indigo-500 hover:bg-opacity-75 px-3 py-2 rounded-md text-sm font-medium" onClick={signOut}>Logout</button>
                </div>
            )
        } else {
            return false

        }
    }
    let history = useHistory()
    return (
        <div class="min-h-full">
            <nav class="bg-indigo-600">
                <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div class="flex items-center justify-between h-24">
                        <Link to="/dashboard">
                        <Logo className="h-16 w-16"/>
                        </Link>
                        <div class="ml-10 flex items-baseline space-x-4">
                            {renderButtons()}
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    )

}
