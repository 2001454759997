import React, { useEffect, useState, useContext, Suspense } from "react";
import { Route, Link, Switch } from "react-router-dom";
import { onSnapshot, collection } from "firebase/firestore";
import { BrowserRouter as Router } from "react-router-dom";
// import logo from "./logo.svg";
import "./App.css";
import {auth } from "./firebase";
import db from "./firebase";

import Application from "components/screens/application";
import Home from "components/screens/dashboard";
import Signup from "components/Auth/Signup";
import Login from "components/Auth/Login";

import Header from 'components/header';

import AuthContext from "contexts/AuthContext";
const ApplicationList = () => {
  const [applicationData, setApplicationsData] = useState([]);
  console.log(applicationData, "application data");
  useEffect(() => {
    const subscribe = onSnapshot(collection(db, "applications"), (snapshot) => {
      setApplicationsData(
        snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
      console.log(snapshot);
    });
    return subscribe;
  }, []);
  return (
    <div>
      <Route path={`/applications/:applicationId`}>
        <Suspense>
        <Application data={applicationData} />
        </Suspense>
      </Route>
    </div>
  );
};

function App() {
  const [applications, setApplications] = useState([]);
  console.log(applications, "applications");
  useEffect(() => {
    const subscribe = onSnapshot(collection(db, "applications"), (snapshot) => {
      setApplications(
        snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
      console.log(snapshot);
    });
    return subscribe;
  }, []);
  const [isAuthenticated, setAuthentication] = useState(
    useContext(AuthContext)
  );
  useEffect(() => {
      const unsubscribe = auth.onAuthStateChanged(user => {
          user ?
          setAuthentication(true) : 
          setAuthentication(false);
          unsubscribe()
      })
  }, []);
  return (
    <AuthContext.Provider value={[isAuthenticated, setAuthentication]}>
      <Router>
        <div className="App">
          <Header/>
          {/* <Link to="/">Home</Link> */}
          <Switch>
          <Route exact path="/" component={Login}/>
          <Route path="/dashboard" component={Home} />
          <Route path="/signup"><Signup/></Route>
          <Route path="/login"><Login/></Route>
          <Route path="/applications/:applicationId">
            <ApplicationList />
          </Route>
          <Route path="*" component={Home} />
          </Switch>
        </div>
      </Router>
    </AuthContext.Provider>
  );
}

export default App;
