import React, { useEffect, useState, useContext } from 'react';
import { Route, Link, } from "react-router-dom";
import { Redirect } from 'react-router';
import { onSnapshot, collection } from "firebase/firestore";
import Application from "components/screens/application";
import db from "firebase";
import AuthContext from 'contexts/AuthContext';
const ApplicationList = () => {
    const [applicationData, setApplicationsData] = useState([]);
    console.log(applicationData, "application data");

    useEffect(() => {
        const subscribe = onSnapshot(collection(db, "applications"), (snapshot) => {
            setApplicationsData(
                snapshot.docs.map((doc) => ({ ...doc.data()[0],  date: doc.data() && doc.data()['date'] && doc.data()['date'].toDate().toUTCString(),formtype: doc.data()['formtype'], title: doc.data()['title'], id: doc.id }))
            );
            console.log(snapshot, 'application snapshot');
        });
        return subscribe;
    }, []);
    const sortedLinkList = applicationData.sort((a,b) => new Date(b.date) - new Date(a.date));
    const linkList = sortedLinkList.map((application) => {
        console.log(application, 'application map', application.subject, typeof application.date, application.date, application.type)
        return (
            console.log(application, 'application map'),
            <li key={application.id}>
                <Link to={`applications/${application.id}`} className="block hover:bg-gray-50">
                    <div className="px-4 py-4 sm:px-6">
                        <div className="flex items-center justify-between">
                            <p className="text-sm font-medium text-indigo-600 truncate">{application['Family Name'] ? application['Family Name'] : application.id}</p>
                            <div className="ml-2 flex-shrink-0 flex">
                                <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 cursor-pointer">
                                    {application.id}
                                </p>
                            </div>
                        </div>
                        <div className="mt-2 sm:flex sm:justify-between">
                            <div className="sm:flex">
                                <p className="flex items-center text-sm text-gray-500">
                                    {/* <UsersIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" /> */}
                                    {application.formtype}
                                </p>
                                <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                                    {/* <LocationMarkerIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" /> */}
                                    {application.title}
                                </p>
                            </div>
                            <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                                {/* <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" /> */}
                                <p>
                                    {application.date}
                                </p>
                            </div>
                        </div>
                    </div>
                </Link>
            </li>

        );
    });
    const [isAutheticated, setAutheticated] = useContext(AuthContext)
    if (isAutheticated === false) return <Redirect to="/" />
    return (
        <div className="bg-white shadow overflow-hidden sm:rounded-md container">
            <ul className="divide-y divide-gray-200">
                <Route path={`/:applicationId`}>
                    {linkList}
                </Route>
            </ul>
        </div>
    );
};




class Home extends React.Component {
    render() {
        return (
            <div className="min-h-full bg-gray-100 h-screen">
                <div className="bg-indigo-600 pb-32">
                    <header className="py-10">
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                            <h1 className="text-3xl font-bold text-white text-left">Applications</h1>
                        </div>
                    </header>
                </div>

                <main className="-mt-32">
                    <div className="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
                        {/* my content*/}
                        <ApplicationList />
                    </div>
                </main>
            </div>
        )
    }
}

export default Home