import React, { useContext, Suspense } from "react";
import { useParams } from "react-router-dom";
import { Redirect } from "react-router";
import AuthContext from "contexts/AuthContext";
import { auth } from "firebase";

function elementInArrayByString(array, string) {
    return array.find((element) => {
        return element.string === string
    })
}

function includesStringInArray(array, string) {
    return array.includes(string)
}

function findEntry(array, target) {
    const findEntryTarget = array.find((field) => {
        return field.some((item) => {
            return item === target
        })
    })
    return findEntryTarget
}

// function filterElementInArrayByString

function ListItem(props, key) {
    const sorted = props.value.sort((a, b) => {
        return b[1] - a[1]
    })
    const findCounsellingServiceEntry = props.value.find((field) => {
        return field.some((item) => {
            return item === 'Language Counselling Service'
        })
    })
    const findNationalityEntry = props.value.find((field) => {
        return field.some((item) => {
            return item === 'Nationality'
        })
    })
    // console.log(props.value, findEntry('Nationality'), findEntry(props.value,'Language Counselling Service'))
    console.log(findCounsellingServiceEntry, 'find counselling', findNationalityEntry, 'find nationality')
    return (
        <div key={props.key } className={`${props.id % 2 === 0 ? 'bg-white' : 'bg-gray-50'} px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'`}>
            {props.value.length > 1 && <dt className="text-sm font-medium text-gray-500">{findNationalityEntry[0]}</dt>}
                {props.value.length > 1 && <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <span className="block">{`${findNationalityEntry[1].toString()}`}</span>
                    <span className="block pt-2 mt-2 border-t-2 border-gray-100">{`${findCounsellingServiceEntry[0]}: ${findCounsellingServiceEntry[1]}`}</span>
                </dd>}
                {props.value.length < 2 && <dt className="text-sm font-medium text-gray-500">{props.value[0][0]}</dt>}
                {props.value.length < 2 && <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{props.value[0][1].length === 0 ? 'Empty' : Boolean(props.value[0][1]) ? props.value[0][1].toString() : props.value[0][1]}</dd>}
        </div>
    )
}

/* This example requires Tailwind CSS v2.0+ */
// import { PaperClipIcon } from '@heroicons/react/solid'

const Example = () => {
    return (
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Applicant Information</h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">Personal details and application.</p>
            </div>
            <div className="border-t border-gray-200">
                <dl>
                    <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Full name</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">Margot Foster</dd>
                    </div>
                </dl>
            </div>
        </div>
    )
}


function DetailsList(props, key) {
    const { id, subject, to, date, formtype, title, ...newData } = props.details;
    const details = Object.values(newData);
    let detailOutput;
    const newArray = details.map(({ type, order, ...details }) => details)
    const listItems = newArray.map((detail, i) => {
        detailOutput = Object.entries(detail)
        console.log(detailOutput)
        return <ListItem key={id} id={i} value={detailOutput} />
    });
    return (

        <div className="bg-gray-50">
            <div className="max-w-4xl mx-auto py-12">
                <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                    <div className="flex justify-between">
                        <div className="px-4 py-5 sm:px-6 text-left">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">Applicant Information</h3>
                            <p className="mt-1 max-w-2xl text-sm text-gray-500">Personal details and application.</p>
                        </div>
                        <span className="m-4 px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800 h-full cursor-pointer" onClick={() => { navigator.clipboard.writeText(`http://applications.brightworld.co.uk/applications/${props.id}`) }}>{props.id}</span>
                    </div>
                    <div className="border-t border-gray-200 text-left">
                        <dl>
                            {listItems}
                        </dl>
                    </div>
                </div>
            </div>
        </div>
    )
}


const Application = ({ data }) => {
    const { applicationId } = useParams();
    const [isAutheticated, setAutheticated] = useContext(AuthContext)
    if (isAutheticated === false) return <Redirect to="/" />
    console.log('application individual data in root', data)
    const application = data.find(p => p.id === applicationId)
    console.log(application, 'application')
    // const {id, ...noIDApplication} = application
    // console.log(noIDApplication, 'rest')
    let applicationData
    if (application) {
        console.log(Object.values(application), 'application in dasb')
        applicationData = (
            <div>
                <Suspense fallback={<h1 styles={{ fontSize: '3000px', color: 'green' }}>Loading...</h1>}>
                    <DetailsList details={application} id={application.id} />
                </Suspense>
            </div>
        );
    } else {
        applicationData = <span>Sorry. Application does not exist.</span>
    }
    console.log(application, applicationData, 'application find')

    return (
        <div className="container">
            {/* <DetailsList details={[1, 2]} /> */}
            <div>{applicationData}</div>
        </div>
    )
}

export default Application